.chat-messages .chat-message {
  margin-bottom: 1em;
  padding: 0.25em;
  background-color: #eee;
}

.chat-message .who {
  font-weight: bold;
}

.chat-message .ts {
  color: gray;
  font-size: 80%;
  text-align: right;
}
